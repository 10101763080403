import { type SubmitHandler, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import z from 'zod'
import { Button } from 'components/ui/Button'
import { Input } from 'components/ui/Input'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { LoadingButton } from 'components/ui/LoadingButton'
import { CreateUserParams } from 'helpers/api/post-api'
import { useCreateUserMutate } from 'hooks/api/useMutation.hooks'
import { useGetAdminUsers } from 'hooks/api/useQuery.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { userKeyFactory } from 'helpers/api/factories/userKey'
import { Skeleton } from 'components/ui/Skeleton'
import { CreatableSelect } from 'components/ui/CreatableSelect'

interface Props {
  user?: CreateUserParams
  open?: boolean
  toggleOpen: (open: boolean) => void
}
type SelectedVal = { label: string; value: string | number }

const userSchema = z.object({
  fname: z.string().min(1, { message: 'First name is required.' }),
  lname: z.string().min(1, { message: 'Last name is required.' }),
  username: z.string().email({ message: 'Invalid email address.' }),
  linked_in_url: z.string().optional().refine(
    (value) => !value || z.string().url().safeParse(value).success,
    { message: 'Invalid URL.' }
  ),
  hubspot_owner_id: z.string().min(1, { message: 'Hubspot owner is required.' })
})
type UserSchema = z.infer<typeof userSchema>

export function NewClientDialog({ open, toggleOpen }: Props) {
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useCreateUserMutate()
  const { data, isFetching : fetchingAdmins } = useGetAdminUsers()

  const form = useForm<UserSchema>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      fname: '',
      lname: '',
      username: '',
      linked_in_url: '',
      hubspot_owner_id: ''
    }
  })

  const admins = data?.data.map(admin => ({ label: admin.name, value: admin.user_id }))

  const handleSubmit: SubmitHandler<UserSchema> = values => {
    mutate(
      {
        hs_owner_id: +values.hubspot_owner_id,
        // is_admin: boolean, // not sure if this is needed
        linked_in_url: values.linked_in_url || '',
        person: { fname: values.fname, lname: values.lname },
        status: 'approved',
        username: values.username
      },
      {
        onSuccess: async () => {
          toast({
            variant: 'success',
            description: 'User created successfully',
          })
          await queryClient.invalidateQueries({
            queryKey: userKeyFactory.user()
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[624px]'>
        <DialogHeader>
          <DialogTitle className='text-2xl'>Create Pre-Approved User</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form className="grid gap-4" onSubmit={form.handleSubmit(handleSubmit)}>
            <div className='flex-1'>
              <FormField
                control={form.control}
                name='fname'
                render={({ field }) => {
                  const { onChange, value, ...rest } = field
                  return (
                    <FormItem>
                      <FormLabel>First name</FormLabel>
                      <FormControl>
                        <Input
                          {...rest}
                          value={value || ''}
                          onChange={value => onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>
            <div className='flex-1'>
              <FormField
                control={form.control}
                name='lname'
                render={({ field }) => {
                  const { onChange, value, ...rest } = field
                  return (
                    <FormItem>
                      <FormLabel>Last name</FormLabel>
                      <FormControl>
                        <Input
                          {...rest}
                          value={value || ''}
                          onChange={value => onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>
            <div className='flex-1'>
              <FormField
                control={form.control}
                name='username'
                render={({ field }) => {
                  const { onChange, value, ...rest } = field
                  return (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          {...rest}
                          value={value || ''}
                          onChange={value => onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>
            <div className='flex-1'>
              <FormField
                control={form.control}
                name='linked_in_url'
                render={({ field }) => {
                  const { onChange, value, ...rest } = field
                  return (
                    <FormItem>
                      <FormLabel>Linked In</FormLabel>
                      <FormControl>
                        <Input
                          {...rest}
                          value={value || ''}
                          onChange={value => onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>
            <div className='flex-1'>
              <FormField
                control={form.control}
                name={'hubspot_owner_id'}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Hubspot owner</FormLabel>
                      {fetchingAdmins ? (
                        <Skeleton className='w-full h-12 bg-gray-300' />
                      ) : (
                        <CreatableSelect
                          {...field}
                          options={admins}
                          value={admins?.find(ad => +ad.value === +field.value)}
                          onChange={(selectedOption, _action) => {
                            const option = selectedOption as SelectedVal
                            field.onChange(option.value + '')
                          }}
                        />
                      )}
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
