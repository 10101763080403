import React, { createContext, useContext, useState } from 'react'

interface EditableRow {
  id: number
  value: any // can contain anything you need
}

interface EditableTableContextProps {
  editingRows: EditableRow[]
  deletingRowId: number | null
  setEditingRows: React.Dispatch<React.SetStateAction<EditableRow[]>>
  setDeletingRowId: React.Dispatch<React.SetStateAction<number | null>>
}

const EditableTableContext = createContext<EditableTableContextProps | undefined>(undefined)

const EditableTableProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [editingRows, setEditingRows] = useState<EditableRow[]>([])
  const [deletingRowId, setDeletingRowId] = useState<number | null>(null)

  const value = { editingRows, setEditingRows, deletingRowId, setDeletingRowId }

  return (
    <EditableTableContext.Provider value={value}>
      {children}
    </EditableTableContext.Provider>
  )
}

const useEditTableContext = () => {
  const context = useContext(EditableTableContext)
  if (!context) {
    throw new Error('useEditTableContext must be used within a EditableTableContext.Provider')
  }

  const { editingRows, setEditingRows, deletingRowId, setDeletingRowId } = context

  return {
    editingRows,
    deletingRowId,
    addOrUpdateRowToEdit: ({id, value}: EditableRow) => {
      setEditingRows((prev) => {
        const existingRow = prev.find(value => value.id === id)
        if (existingRow) {
          return prev.map(editingItem =>
            editingItem.id === id ? { id, value } : editingItem
          ) as EditableRow[]
        }
        return [...prev, { id, value }] as EditableRow[]
      })
    },
    removeRowToEdit: (id: number) => {
      setEditingRows((prev) => prev.filter((value) => value.id !== id))
    },
    isEditingRow: (id: number) => editingRows.some((value) => value.id === id),
    getEditingRow: (id: number) => editingRows.find((value) => value.id === id),
    isEditingRows: () => editingRows.length > 0,
    clearEditingRows: () => setEditingRows([]),
    setDeletingRowId
  }
}


export { useEditTableContext, EditableTableProvider }