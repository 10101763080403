import { type ColumnDef } from '@tanstack/react-table'
import type { InvestorResponse } from 'types/api-types'
import { dateFormat } from 'services/TimeUtils'
import { useEditTableContext } from 'contexts/EditableTable.context'
import { Pencil } from 'lucide-react'
import { Button } from 'components/ui/Button'

export const Columns: () => ColumnDef<InvestorResponse>[] = () => {
    const {
      addOrUpdateRowToEdit,
      clearEditingRows
    } = useEditTableContext()

   return [
    {
      accessorKey: 'name',
      header: () => <span>Deal</span>,
      cell: info => {
        const { original } = info.row

        return (
          <>
            <span className='hidden md:flex items-center space-x-3'>
              <span className='text-[#6DB557] font-semibold text-sm mdlg:text-base'>
                {original.name}
              </span>
            </span>

            <div className='flex md:hidden items-center space-x-3'>
              <div className='flex flex-col flex-1 space-y-1'>
                <div className='flex justify-between'>
                  <span className='text-[#6DB557] font-semibold text-sm'>{original.name}</span>
                </div>
              </div>
            </div>
          </>
        )
      },
      meta: { thClassName: 'hidden md:table-cell' },
    },
    {
      accessorKey: 'created_at',
      header: () => <span>Date created</span>,
      cell: info => {
        const { created_at } = info.row.original
        return <span className='text-sm mdlg:text-base'>{dateFormat(created_at)}</span>
      },
      meta: { thClassName: 'hidden md:table-cell', tdClassName: 'hidden md:table-cell' },
      size: 200
    },
    {
      header: 'Actions',
      accessorKey: '',
      size: 100,
      meta: { thClassName: 'text-right', tdClassName: 'text-right' },
      cell: ({ row }) => (
        <Button
          type='button'
          size='sm'
          variant='ghost'
          onClick={() => { clearEditingRows(); addOrUpdateRowToEdit({ id: row.original.id, value: row.original.name }) }}
        >
          <Pencil className='w-4 h-4 text-success'/>
        </Button>
      )
    }
  ]
}