import { type ColumnDef } from '@tanstack/react-table'
import type { UserInfo } from 'types/api-types'
import { dateFormat } from 'services/TimeUtils'
import { cn } from 'services/utils'
import hubspot_logo from 'assets/images/hubspot.svg'
import { dollarFormat } from 'constants/DollarsFormat'

const statusMapper: Record<string, { className: string; mobileClassName: string; label: string }> =
  {
    approved: {
      className: 'text-[#6DB557] border-[#6DB557] bg-green-50/20',
      mobileClassName: 'bg-[#6DB557]',
      label: 'Approved'
    },
    pending_approval: {
      className: 'text-[#F88A0A] border-[#F88A0A] bg-orange-50/20',
      mobileClassName: 'bg-[#F88A0A]',
      label: 'Pending'
    },
    not_approved: {
      className: 'text-[#F47777] border-[#F47777] bg-red-50/20',
      mobileClassName: 'bg-[#F47777]',
      label: 'Blocked'
    },
    disabled: {
      className: 'text-[#777777] border-[#777777] bg-gray-50/20',
      mobileClassName: 'bg-[#777777]',
      label: 'Archived'
    }
  }

export const columns: ColumnDef<UserInfo>[] = [
  {
    accessorKey: 'name',
    header: () => <span>Client</span>,
    cell: info => {
      const { original } = info.row

      return (
        <>
          <div className='hidden md:block'>
            <div className='flex items-center space-x-3'>
              <a
                target='__blank'
                href={`https://app.hubspot.com/contacts/8426488/contact/${original.hubspot_id}/`}
              >
                <img className='h-4' src={hubspot_logo} />
              </a>
              <div className='text-[#6DB557] font-semibold text-sm mdlg:text-base'>{original.name}</div>
            </div>
            <div className='text-gray-400 font-semibold text-xs'>{original.username}</div>
          </div>
          <div className='flex md:hidden items-center space-x-3'>
            <div className='flex flex-col items-center'>
              <a
                target='__blank'
                href={`https://app.hubspot.com/contacts/8426488/contact/${original.hubspot_id}/`}
              >
                <img className='h-4' src={hubspot_logo} />
              </a>
            </div>
            <div className='flex flex-col flex-1 items-start space-y-1'>
              <div className='text-[#6DB557] font-semibold text-sm mdlg:text-base'>{original.name}</div>
              <div className='text-gray-400 font-semibold text-xs'>{original.username}</div>
            </div>
            <span
              className={cn('rounded-full w-2 h-2', statusMapper[original.status]?.mobileClassName)}
            />
          </div>
        </>
      )
    },
    meta: { thClassName: 'hidden md:table-cell' }
  },
  {
    accessorKey: 'invested_current',
    accessorFn: row => dollarFormat(row.invested_current),
    header: () => <span>Invested (this&nbsp;year)</span>,
    cell: info => {
      const { invested_current } = info.row.original
      return (
        <span className='hidden md:inline text-sm mdlg:text-base'>{dollarFormat(invested_current)}</span>
      )
    },
    meta: { thClassName: 'hidden md:table-cell', tdClassName: 'hidden md:table-cell' },
    size: 210
  },
  {
    accessorKey: 'invested_all_time',
    accessorFn: row => dollarFormat(row.invested_all_time),
    header: () => <span>Invested (All&#8209;time)</span>,
    cell: info => {
      const { invested_all_time } = info.row.original
      return (
        <span className='hidden md:inline text-sm mdlg:text-base'>{dollarFormat(invested_all_time)}</span>
      )
    },
    meta: { thClassName: 'hidden md:table-cell', tdClassName: 'hidden md:table-cell' },
    size: 210
  },
  {
    accessorKey: 'status',
    header: () => 'Status',
    cell: info => {
      const { status } = info.row.original
      return (
        <span
          className={cn(
            'border rounded-full px-2 py-1 font-medium text-sm',
            statusMapper[status]?.className
          )}
        >
          {statusMapper[status]?.label}
        </span>
      )
    },
    meta: { thClassName: 'hidden md:table-cell', tdClassName: 'hidden md:table-cell' },
    size: 140
  },
  {
    accessorKey: 'created_at',
    header: 'Date created',
    cell: info => {
      const { created_at } = info.row.original
      return <span className='text-sm mdlg:text-base'>{dateFormat(created_at)}</span>
    },
    meta: { thClassName: 'hidden md:table-cell', tdClassName: 'hidden md:table-cell' },
    size: 160
  },
  {
    accessorKey: 'last_sign_in_at',
    header: 'Last login',
    cell: info => {
      const { last_sign_in_at } = info.row.original
      return <span className='text-sm mdlg:text-base'>{dateFormat(last_sign_in_at)}</span>
    },
    meta: { thClassName: 'hidden md:table-cell', tdClassName: 'hidden md:table-cell' },
    size: 130
  }
]
