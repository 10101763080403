import { authenticatedFetch, currentPlatform } from 'services/authentication'
import configuration from 'services/config'
import {
  Member,
  OpportunityResponse,
  Organization,
  InvestorResponse,
  PropertiesResponse,
  AddUpdateFundResponse,
  OpportunityCarriesResponse,
  UserInfo
} from 'types/api-types'
import { EntitiesResponse, UserSearchResponse } from './get-api'

export interface SaveHubspotParams {
  email?: string
  person_fname?: string
  person_lname?: string
  register_token?: string
}
export async function saveHubspotContact(params: SaveHubspotParams) {
  const response = await fetch(configuration.apiUrl + 'save_hubspot', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify({ ...params, platform: currentPlatform() })
  })

  if (response.ok) return response
  throw new Error('Something went wrong')
}

export interface LoginParams {
  email: string
  password: string
}
export async function logIn({ email, password }: LoginParams) {
  const response = await fetch(configuration.apiUrl + 'login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify({
      user: {
        username: email,
        password,
        platform: currentPlatform()
      }
    })
  })

  if (!response.ok) {
    const res = await response.json()
    const error =
      res.message ?? 'There was a problem connecting to the server. Please try again later.'
    throw new Error(error)
  }
  return response
}

export interface UpdateOrganizationParams {
  id: number
  userId: number
  name: string
}
export async function updateOrganizationName({ id, name }: UpdateOrganizationParams) {
  try {
    return (await authenticatedFetch(`organizations/${id}`, {
      body: JSON.stringify({ name }),
      method: 'put'
    })) as Organization
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface InviteMemberParams {
  id: number
  email: string
}
export async function inviteMemberToOrganization({ id, email }: InviteMemberParams) {
  try {
    return (await authenticatedFetch(`organizations/${id}/invite_member`, {
      body: JSON.stringify({
        email,
        role: 'user',
        first_name: 'Organization',
        last_name: 'Member'
      }),
      method: 'post'
    })) as { members: Member[]; message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface RemoveMemberParams {
  id: number
  userId: number
  email: string
}
export async function removeMemberFromOrganization({ id, email }: RemoveMemberParams) {
  try {
    return (await authenticatedFetch(`organizations/${id}/remove_member`, {
      body: JSON.stringify({
        email
      }),
      method: 'post'
    })) as { message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function removeAdminMemberFromOrganization(id: number) {
  try {
    return await authenticatedFetch(`users/${id}/remove_as_admin`, { method: 'get' })
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface MergeMemberParams {
  organizationId: number
  memberId: number
}
export async function mergeMegerIntoOrganization(params: MergeMemberParams) {
  try {
    return (await authenticatedFetch(
      `organizations/${params.organizationId}/merge_member?merge_user_id=${params.memberId}`,
      {}
    )) as { members: Member[]; message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface AddNewEntityParams {
  userId: number
  organizationId: number
  name: string
}
export async function addNewEntityName(params: AddNewEntityParams) {
  try {
    return (await authenticatedFetch(`entities`, {
      method: 'POST',
      body: JSON.stringify({
        name: params.name,
        user_id: params.userId,
        organization_id: params.organizationId
      })
    })) as { entity: EntitiesResponse; message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface EditEntityParams {
  id: number
  name: string
}
export async function editEntityName({ id, name }: EditEntityParams) {
  try {
    return (await authenticatedFetch(`entities/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({ name, id })
    })) as { entity: EntitiesResponse; message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function deleteEntityName(id: number) {
  try {
    return (await authenticatedFetch(`entities/${id}`, {
      method: 'DELETE'
    })) as { entity: EntitiesResponse; message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface CreateClientPasswordParams {
  userId: number
  password: string
}
export async function createClientPassword({ userId, password }: CreateClientPasswordParams) {
  try {
    return await authenticatedFetch(`passwords/create_client_password`, {
      method: 'PUT',
      body: JSON.stringify({ id: userId, password })
    })
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export type ChangePasswordParams = {
  currentPassword: string
  newPassword: string
}
export async function changePassword(params: ChangePasswordParams) {
  const { currentPassword, newPassword } = params
  try {
    return authenticatedFetch('passwords/change', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        platform: currentPlatform()
      },
      body: JSON.stringify({
        current_password: currentPassword,
        new_password: newPassword
      })
    })
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function updateOpportunity(params: Partial<OpportunityResponse>) {
  try {
    return authenticatedFetch(`opportunities/${params.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        platform: currentPlatform()
      },
      body: JSON.stringify(params)
    }) as Promise<OpportunityResponse>
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface OpportunitySummaryParams {
  id: number
  schedule_call_url: string | null
  tag_list: string | null
  company_url: string | null
  short_description_html?: string | undefined
  long_description_html?: string | undefined
}
export async function updateOpportunitySummary(params: OpportunitySummaryParams) {
  try {
    return authenticatedFetch(`opportunities/${params.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        platform: currentPlatform()
      },
      body: JSON.stringify(params)
    }) as Promise<OpportunityResponse>
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface KeyPointsParams {
  id: number
  keyPoints: Partial<OpportunityResponse['key_points'][number]>[]
}
export async function updateKeyPoints(params: KeyPointsParams) {
  try {
    return authenticatedFetch(`opportunities/${params.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        platform: currentPlatform()
      },
      body: JSON.stringify({ key_points: params.keyPoints })
    }) as Promise<OpportunityResponse>
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface DueDiligenceParams {
  id: number
  links: Partial<OpportunityResponse['links'][number]>[]
}
export async function updateDueDiligence(params: DueDiligenceParams) {
  try {
    return authenticatedFetch(`opportunities/${params.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        platform: currentPlatform()
      },
      body: JSON.stringify({ links: params.links })
    }) as Promise<OpportunityResponse>
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface DataRoomParams {
  id: number
  links: Partial<OpportunityResponse['links'][number]>[]
}
export async function updateDataRoom(params: DataRoomParams) {
  try {
    return authenticatedFetch(`opportunities/${params.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        platform: currentPlatform()
      },
      body: JSON.stringify({ links: params.links })
    }) as Promise<OpportunityResponse>
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface CompanyUpdatesParams {
  id: number
  links: Partial<OpportunityResponse['links'][number]>[]
}
export async function updateCompanyUpdates(params: CompanyUpdatesParams) {
  try {
    return authenticatedFetch(`opportunities/${params.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        platform: currentPlatform()
      },
      body: JSON.stringify({ links: params.links })
    }) as Promise<OpportunityResponse>
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface CreateInvestorParams {
  id?: number | null
  name: string
}

export async function createInvestor({ id = null, name }: CreateInvestorParams) {
  try {
    return (await authenticatedFetch(`investors/${id || ''}`, {
      body: JSON.stringify({
        name
      }),
      method: id ? 'put' : 'post'
    })) as Promise<InvestorResponse>
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface AddOrUpdateFundParams {
  opportunity_id: number
  fund_id: number
  investment_value: number
  reserve_amount: number
}
export async function addOrUpdateFund(params: AddOrUpdateFundParams) {
  const { opportunity_id, ...rest } = params
  try {
    return authenticatedFetch(`opportunities/${opportunity_id}/add_or_update_fund`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        platform: currentPlatform()
      },
      body: JSON.stringify(rest)
    }) as Promise<AddUpdateFundResponse>
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface DeleteFundParams {
  opportunityId: number
  fundId: number
}
export async function deleteFund(params: DeleteFundParams) {
  try {
    return authenticatedFetch(
      `opportunities/${params.opportunityId}/remove_fund?fund_id=${params.fundId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          platform: currentPlatform()
        }
      }
    ) as Promise<OpportunityResponse>
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface CreatePropertyParams {
  name: string
  property_type: string
}
export async function createProperty(params: CreatePropertyParams) {
  try {
    return authenticatedFetch('properties', {
      method: 'POST',
      body: JSON.stringify(params)
    }) as Promise<PropertiesResponse>
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface UpdateOpportunityCarryParams {
  carry_id: number
  carry: number
}

export async function updateOpportunityCarry({ carry_id, carry }: UpdateOpportunityCarryParams) {

  try {
    return (await authenticatedFetch(`carries/${carry_id}`, {
      method: 'PUT',
      body: JSON.stringify({ carry: carry }),
      headers: {
        'Content-Type': 'application/json',
        platform: currentPlatform()
      }
    })) as OpportunityCarriesResponse
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function deleteOpportunityCarry(carry_id: number) {
  try {
    return (await authenticatedFetch(`carries/${carry_id}`, {
      method: 'DELETE'
    })) as { message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface BulkUpdateOpportunityCarriesParams {
  opportunity_id: number
  carries: OpportunityCarriesResponse[]
}

export async function bulkUpdateOpportunityCarries({ opportunity_id, carries }: BulkUpdateOpportunityCarriesParams) {
  try {
    return (await authenticatedFetch(`carries/bulk_update`, {
      method: 'POST',
      body: JSON.stringify({
        opportunity_id: opportunity_id,
        carries: carries
      }),
      headers: {
        'Content-Type': 'application/json',
        platform: currentPlatform()
      }
    })) as { message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface CreateCarryUserParams {
  opportunity_id: number
  user_id: number
  carry: number
}

export async function createCarryUser(params: CreateCarryUserParams) {
  try {
    return (await authenticatedFetch(`carries`, {
      method: 'POST',
      body: JSON.stringify(params),
    })) as UserSearchResponse
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface CreateUserParams {
  id?: string
  hs_owner_id: number
  is_admin?: boolean
  linked_in_url: string
  person: { fname: string; lname: string }
  status: string
  username: string
}

export async function createOrUpdateUser({ id, ...rest }: CreateUserParams) {
  try {
    return (await authenticatedFetch(`users/${id || ''}`, {
      body: JSON.stringify(rest),
      method: id ? 'put' : 'post'
    })) as Promise<UserInfo>
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}
