// Dummy platform switcher
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from 'components/ui/Tabs'

interface Props {
  value?: string
  onChange?: (value: string) => void
  className?: string
}

export function PlatformTabs(props: Props) {
  const { value = 'mvp', onChange, className } = props

  return (
    <Tabs value={value} className={className} onValueChange={onChange}>
      <TabsList className="grid w-full grid-cols-3">
        <TabsTrigger value="mvp">MVP</TabsTrigger>
        <TabsTrigger value="champion">Champion</TabsTrigger>
        <TabsTrigger value="pending">Pending</TabsTrigger>
      </TabsList>
    </Tabs>
  )
}
