import { useState, useEffect } from 'react'
import { X } from 'lucide-react'
import { Button } from 'components/ui/Button'
import ScrollContainer from 'components/core/ScrollContainer'
import HeaderNew from 'components/core/HeaderNew'
import { Table } from 'components/core/v2/Table'
import { ClientsMobileFilter } from 'components/core/v2/clients/MobileFilters'
import { Filters } from 'components/core/v2/clients/Filters'
import { useGetUsers } from 'hooks/api/useQuery.hooks'
import type { UserInfo } from 'types/api-types'
import useDebounce from 'services/useDebounce'
import { NewClientDialog } from 'components/core/v2/clients/NewClientDialog'
import { columns } from 'components/core/v2/clients/Columns'
import { pendingColumns } from 'components/core/v2/clients/PendingColumns'
import { useTablePagination, useTable, useTableSort } from 'hooks/useTable.hooks'
import { TablePagination } from 'components/core/v2/TablePagination'
import { PlatformTabs } from 'components/core/v2/clients/PlatformTabs'
import { ManageClientDetailsDialog } from 'components/modals/manageDetails/ManageClientDetailsDialog'
import { useParams, useHistory } from 'react-router-dom'

interface DefaultPagination {
  order_by: string
  limit: number
  offset: number
  current_page: number
  order?: string
}

interface DefaultFilters {
  search: string
  status: string[]
  platform: 'mvp' | 'champion' | 'pending'
}

const LOCALSTORAGE_NAME = 'mvp_clients_sq'

const DEFAULT_PAGINATION = {
  order_by: 'created_at',
  order: 'desc',
  limit: 50,
  offset: 0,
  current_page: 1,
}

const DEFAULT_FILTERS: DefaultFilters & { pagination: DefaultPagination } = {
  search: '',
  status: ['approved'],
  platform: 'mvp',
  pagination: DEFAULT_PAGINATION
}

const getLocalStorageSearchQuery = () => {
  const searchQuery = localStorage.getItem(LOCALSTORAGE_NAME)
  if (!searchQuery) return DEFAULT_FILTERS
  return JSON.parse(searchQuery) as DefaultFilters & { pagination: DefaultPagination }
}

export default function Clients() {
  const { user_id } = useParams<{ user_id: string }>()
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const [openClientDialog, setOpenClientDialog] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<number | undefined>(+user_id || undefined)
  const [search, setSearch] = useState<string>(() => getLocalStorageSearchQuery().search)
  const [status, setStatus] = useState<string[]>(() => getLocalStorageSearchQuery().status)
  const [platform, setPlatform] = useState<string>(() => getLocalStorageSearchQuery().platform)
  const debouncedSearch = useDebounce(search, 500)
  const history = useHistory()

  const { pagination, onPaginationChange } = useTablePagination({
    pageIndex: getLocalStorageSearchQuery().pagination.current_page - 1,
    pageSize: getLocalStorageSearchQuery().pagination.limit
  })
  const { sorting, onSortingChange, orderBy, order } = useTableSort([
    {
      id: getLocalStorageSearchQuery().pagination.order_by,
      desc: !!(getLocalStorageSearchQuery().pagination.order === 'desc')
    }
  ])

  const { data, isLoading, isFetching } = useGetUsers({
    order_by: orderBy === 'name' ? 'people.fname' : orderBy,
    order,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    current_page: pagination.pageIndex + 1,
    search_term: debouncedSearch,
    statuses: debouncedSearch.length ? undefined : status,
    platform
  })

  const tableData = data?.data.map(d => ({ ...d, linkUrl: `/admin/clients/${d.id}` }))

  const table = useTable<UserInfo>({
    columns: platform === 'pending' ? pendingColumns : columns,
    data: tableData ?? [],
    manualSorting: true,
    manualPagination: true,
    onSortingChange,
    onPaginationChange,
    pageCount: Math.ceil(+(data?.count ?? 1) / pagination.pageSize),
    state: { pagination, sorting },
    isLoading: isLoading || isFetching,
    defaultColumn: {
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    }
  })

  const saveToLocalStorage = () => {
    const pageinationToStore = {
      order_by: orderBy,
      order,
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
      current_page: pagination.pageIndex + 1
    }

    localStorage.setItem(
      LOCALSTORAGE_NAME,
      JSON.stringify({ search: debouncedSearch, status, platform, pagination: pageinationToStore })
    )
  }

  const resetTable = () => {
    table.setPageIndex(0)
  }

  const resetFilters = () => {
    setSearch(DEFAULT_FILTERS.search)
    setStatus(DEFAULT_FILTERS.status)
    setPlatform(DEFAULT_FILTERS.platform)
    table.setSorting([{ id: DEFAULT_PAGINATION.order_by, desc: DEFAULT_PAGINATION.order === 'desc' }])
  }

  const handleRowClick = (row: any) => {
    setSelectedUser(row.id)
  }

  useEffect(() => {
    if (user_id) setSelectedUser(+user_id || undefined)
  }, [user_id])

  useEffect(() => {
    saveToLocalStorage()
  }, [saveToLocalStorage])

  return (
    <ScrollContainer
      hasHeader
      className=''
      goToTop={undefined}
      setGoToTop={undefined}
      isScrolled={isScrolled}
      setIsScrolled={setIsScrolled}
    >
      <HeaderNew />

      <div className='max-w-[1500px] mx-auto px-4 isolate'>
        <div className='flex mdlg:items-center mdlg:flex-row mt-10 justify-between'>
          <h1 className='m-0 text-4xl montserrat font-bold'>
            Clients {data?.count && <span className='text-2xl font-normal'>({data.count})</span>}
          </h1>

          <div className='flex items-center mdlg:ml-auto'>
            <PlatformTabs className='mdlg:block hidden mr-4' value={platform} onChange={setPlatform} />

            <ClientsMobileFilter
              filterCount={status.length}
              className='inline-block mdlg:hidden'
            >
              <Filters
                search={search}
                status={status}
                setSearch={setSearch}
                setStatus={setStatus}
                resetTable={resetTable}
                className='flex-col space-x-0 space-y-6'
              />
              <div className='flex space-x-4 mt-6'>
                <Button
                  variant='outline'
                  size='sm'
                  className='flex-1 border-[#DEDEE3]'
                  onClick={resetFilters}
                >
                  Reset
                </Button>
                {/* This is a dummy UI button. All the filters are applied when filter logics apply. */}
                <Button size='sm' className='flex-1 bg-[#6DB557]'>
                  Apply
                </Button>
              </div>
            </ClientsMobileFilter>

            <Filters
              search={search}
              status={status}
              setSearch={setSearch}
              setStatus={setStatus}
              resetTable={resetTable}
              className='hidden mdlg:flex'
            />

            <Button
              variant='ghost'
              size='sm'
              onClick={resetFilters}
              className='mx-4 hidden mdlg:flex'
            >
              Reset
              <X className='w-4 h-4 ml-1' />
            </Button>

            <Button
              size='sm'
              onClick={() => setOpenClientDialog(true)}
              variant="success"
              className='ml-4 mdlg:ml-0'
            >
              New Client
            </Button>
          </div>
        </div>
        <PlatformTabs className='mdlg:hidden mt-4' value={platform} onChange={setPlatform} />
        <Table<UserInfo> table={table} className='mt-4 md:mt-8' onRowClick={handleRowClick} />
        <TablePagination table={table} isLoading={isLoading || isFetching} />
        { openClientDialog && <NewClientDialog open={openClientDialog} toggleOpen={setOpenClientDialog} /> }
        <ManageClientDetailsDialog
          memberId={selectedUser}
          open={!!selectedUser}
          toggleOpen={status => {
            if (!status) setSelectedUser(undefined)

            history.push('/admin/clients')
          }}
        />
      </div>
    </ScrollContainer>
  )
}
