import { useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { zodResolver } from '@hookform/resolvers/zod'
import z from 'zod'

import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'hooks/userToast.hooks'
import { useSearchUserForOpportunityCarryQuery } from 'hooks/api/useQuery.hooks'
import { useCreateCarryUserMutate } from 'hooks/api/useMutation.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { Button } from 'components/ui/Button'
import { Input } from 'components/ui/Input'
import { LoadingButton } from 'components/ui/LoadingButton'

interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  id: number
}

const carryClientSchemaSchema = z.object({
  id: z.number().int().positive(),
  carry: z.number().min(0).max(100),
  user_id: z.string()
})

type CarryClientSchema = z.infer<typeof carryClientSchemaSchema>

export function AddCarryClientDialog({ id, open, toggleOpen }: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState('');

  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { mutate: createCarryUser } = useCreateCarryUserMutate()
  const { data: users } = useSearchUserForOpportunityCarryQuery(searchTerm, id)

  const form = useForm<CarryClientSchema>({
    resolver: zodResolver(carryClientSchemaSchema),
    defaultValues: {
      id: +id,
      carry: 0,
      user_id: selectedUser
    }
  })

  const handleSubmit: SubmitHandler<CarryClientSchema> = (values) => {
    createCarryUser({
      user_id: +selectedUser,
      opportunity_id: id,
      carry: values.carry
    }, {
      onSuccess: () => {
        setSelectedUser('')
        toggleOpen(false)
        toast({
          variant: 'success',
          description: 'Client added successfully',
          duration: 2000
        })
        queryClient.invalidateQueries({
          queryKey: opportunityKeyFactory.carries(id)
        })
      },
      onError: (err) => {
        toast({
          variant: 'destructive',
          description: err.message ?? 'Client could not be added',
          duration: 2000
        })
      }
    })
  }

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[1035px]'>
        <DialogHeader>
          <DialogTitle>Add client</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            <div className='space-y-4'>
              <FormField
                control={form.control}
                name='user_id'
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Search users</FormLabel>
                      <FormControl>
                        <ReactSearchAutocomplete
                          items={users || []}
                          onSearch={value => setSearchTerm(value)}
                          autoFocus
                          onSelect={(item : any) => setSelectedUser(item.id)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='carry'
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Carry</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type="number"
                          onChange={(e) => field.onChange(parseFloat(e.target.value))}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
