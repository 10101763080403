import { ComponentPropsWithoutRef, useEffect, useState } from 'react'
import { Table } from '@tanstack/react-table'
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react'
import { Button } from 'components/ui/Button'
import { Select, SelectItem, SelectTrigger, SelectValue, SelectContent } from 'components/ui/Select'
import { cn } from 'services/utils'

interface Props<TData> extends ComponentPropsWithoutRef<'div'> {
  table: Table<TData>
  isLoading?: boolean
}

export function TablePagination<TData>({ table, className, isLoading = false }: Props<TData>) {
  const [pageCount, setPageCount] = useState(table.getPageCount())

  useEffect(() => {
    if (!isLoading) setPageCount(table.getPageCount())
  }, [isLoading])

  return (
    <div
      className={cn(
        'flex md:items-center flex-col md:flex-row space-y-4 md:space-y-0 mt-6 mb-12',
        className ?? ''
      )}
    >
      <div className='flex items-center justify-center md:justify-start space-x-4'>
        <p className='font-medium text-base m-0'>
          Showing {table.getState().pagination.pageIndex + 1} of {pageCount} page(s)
        </p>
        <div className='mdlg:flex-1'>
          <Select
            defaultValue={table.getState().pagination.pageSize + ''}
            onValueChange={val => {
              table.setPageSize(+val)
            }}
          >
            <SelectTrigger className='border-slate-400'>
              <SelectValue placeholder='Select page limit' />
            </SelectTrigger>
            <SelectContent className='z-[60] bg-white'>
              {[5, 10, 20, 50].map(limit => (
                <SelectItem key={`limit-${limit}`} value={limit.toString()}>
                  {limit.toString()}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className='flex items-center space-x-3 md:ml-auto justify-center'>
        <div className='space-x-2'>
          <Button
            variant='outline'
            size='sm'
            disabled={!table.getCanPreviousPage()}
            className='border-slate-400'
            onClick={() => {
              if (!table.getCanPreviousPage()) return
              table.setPageIndex(0)
            }}
          >
            <ChevronsLeft className='w-4 h-4 md:w-5 md:h-5' />
          </Button>
          <Button
            variant='outline'
            size='sm'
            disabled={!table.getCanPreviousPage()}
            className='border-slate-400'
            onClick={() => {
              if (!table.getCanPreviousPage()) return
              table.previousPage()
            }}
          >
            <ChevronLeft className='w-4 h-4 md:w-5 md:h-5' />
          </Button>
        </div>
        <div className='space-x-2'>
          <Button
            variant='outline'
            size='sm'
            disabled={!table.getCanNextPage()}
            className='border-slate-400'
            onClick={() => {
              if (!table.getCanNextPage()) return
              table.nextPage()
            }}
          >
            <ChevronRight className='w-4 h-4 md:w-5 md:h-5' />
          </Button>
          <Button
            variant='outline'
            size='sm'
            disabled={!table.getCanNextPage()}
            className='border-slate-400'
            onClick={() => {
              if (!table.getCanNextPage()) return
              table.setPageIndex(table.getPageCount() - 1)
            }}
          >
            <ChevronsRight className='w-4 h-4 md:w-5 md:h-5' />
          </Button>
        </div>
      </div>
    </div>
  )
}
