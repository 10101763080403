import { useState } from 'react'
import { Pencil, Save, X } from 'lucide-react'
import { useQueryClient } from '@tanstack/react-query'
import { useGetOpportunity } from 'hooks/api/useQuery.hooks'
import { useUpdateOpportunityMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'

import { Card, CardContent } from 'components/ui/Card'
import { Button } from 'components/ui/Button'
import { Input } from 'components/ui/Input'
import { dollarFormat } from 'constants/DollarsFormat'

const PORTENTIAL_MOIC = 3

interface Props {
  id: number
}

export function ValuationDetails({ id }: Props) {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { data: deal } = useGetOpportunity(id.toString())
  const { mutate, isLoading: isSavingPotentialMoic } = useUpdateOpportunityMutate()

  const [editPotentialMoic, setEditPotentialMoic] = useState(false)
  const [potentialMoic, setPotentialMoic] = useState(deal?.potential_moic || PORTENTIAL_MOIC)

  const savePotentialMoic = () => {
    if (!deal) return

    mutate(
      { id: deal.id, potential_moic: `${potentialMoic}` },
      {
        onSuccess: () => {
          setEditPotentialMoic(false)
          toast({
            variant: 'success',
            description: 'Projected MOIC updated successfully',
            duration: 2000
          })

          queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(`${deal.id}`),
          })
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  if (!deal) return null

  return (
    <Card className='h-full'>
      <CardContent className='p-5 pt-0'>
          <div className="p-5 grid grid-cols-2 gap-4">
            <div className="mb-4">
              <h2 className="text-lg font-semibold">Valuation</h2>
              <div className="mt-2 flex justify-between max-w-[20rem]">
                <table className="w-full">
                  <tbody>
                    <tr>
                      <td className="font-medium w-8/12">Initial Value:</td>
                      <td className="text-right w-1/12">$</td>
                      <td className="text-right w-3/12">{dollarFormat(deal.valuation.total_invested, false, true)}</td>
                    </tr>
                    <tr>
                      <td className="font-medium w-8/12">Current Value:</td>
                      <td className="text-right w-1/12">$</td>
                      <td className="text-right w-3/12">{dollarFormat(deal.valuation.current_value, false, true)}</td>
                    </tr>
                  </tbody>
                </table>
                </div>
              <div className="mt-2 flex justify-between max-w-[20rem]">
                <span className="font-medium">MOIC: </span>
                <span className="text-right">{Number(deal.moic).toFixed(4)}x</span>
              </div>
            </div>
            {deal.carry_tracking?.overall && (
              <div className="mb-4">
                <h2 className="text-lg font-semibold">Carry</h2>
                <div className="mt-2 flex justify-between max-w-[20rem]">
                  <span className="font-medium">Total: </span>
                  <span className="text-right">{dollarFormat(deal.carry_tracking.overall.carry)}</span>
                </div>
              </div>
            )}
            <div className={`mt-2 flex h-12 justify-between items-center ${editPotentialMoic ? 'max-w-[26rem]' : 'max-w-[23rem]'}`}>
              <span className="font-medium">Projected MOIC: </span>
                {
                  isSavingPotentialMoic ? (
                    <span className='text-right'>Saving...</span>
                  ) :
                  editPotentialMoic ? (
                <span className='text-right flex items-center'>
                  <Input
                    type="number"
                    value={potentialMoic}
                    onChange={(e) => setPotentialMoic(parseFloat(e.target.value))}
                    min="0"
                    max="1000"
                    className='max-w-[8rem] min-w-[6rem]'
                  />
                  <Button
                    type='button'
                    size='sm'
                    variant='ghost'
                    onClick={savePotentialMoic}
                    className='ml-4 mr-4'
                  >
                    <Save className='w-4 h-4 text-success' />
                  </Button>
                  <Button
                    type='button'
                    size='sm'
                    variant='ghost'
                    onClick={() => setEditPotentialMoic(false)}
                  >
                    <X className='w-4 h-4' />
                  </Button>
                </span>
                ) : (
                <span className='text-right h-12'>
                  {Number(potentialMoic).toFixed(4)}x
                  <Button
                    type='button'
                    size='sm'
                    variant='ghost'
                    onClick={() => setEditPotentialMoic(true)}
                    className='ml-4'
                  >
                    <Pencil className='w-4 h-4 text-success' />
                  </Button>
                </span>
                )}
            </div>
          </div>
      </CardContent>
    </Card>
  )
}
