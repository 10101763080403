import { useEditTableContext } from 'contexts/EditableTable.context'
import { ColumnDef } from '@tanstack/react-table'
import { Pencil, Save, Trash2, X } from 'lucide-react'
import { Input } from 'components/ui/Input'
import { Button } from 'components/ui/Button'
import { useUpdateOpportunityCarryMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { useQueryClient } from '@tanstack/react-query'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import { useParams } from 'react-router-dom'
import { CarryData } from '../../deal/OpportunityCarries'
import { dollarFormat } from 'constants/DollarsFormat'

export const Columns: () => ColumnDef<CarryData>[] = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { id : opportunity_id } = useParams<{ id: string }>()
  const { mutate: updateCarry } = useUpdateOpportunityCarryMutate()

  const {
    addOrUpdateRowToEdit,
    removeRowToEdit,
    isEditingRow,
    getEditingRow,
    setDeletingRowId
  } = useEditTableContext()

  return [
    { header: 'Client', accessorKey: 'user.name' },
    {
      header: 'Carry %',
      accessorKey: 'carry',
      size: 200,
      cell: ({ row }) => (
        isEditingRow(row.original.id) ? (
          <Input
            type="number"
            value={getEditingRow(row.original.id)?.value}
            onChange={(e) => {addOrUpdateRowToEdit({ id: row.original.id, value: Number(e.target.value) })}}
            min="0"
            max="100"
            className='max-w-[150px] h-8'
          />
        ) : (
          <>{Number.isInteger(row.original.carry) ? `${row.original.carry}.0` : row.original.carry}</>
        )
      )
    },
    {
      header: 'Carry',
      accessorKey: 'carryTracking',
      size: 200,
      cell: ({ row }) => (
        dollarFormat(row.original.carryTracking)
      )
    },
    {
      header: 'Actions',
      accessorKey: '',
      size: 100,
      maxSize: 100,
      meta: { thClassName: 'text-right', tdClassName: 'text-right' },
      cell: ({ row }) => (
        <>
          {isEditingRow(row.original.id) ? (
            <>
              <Button
                type='button'
                size='sm'
                variant='ghost'
                onClick={() => {
                  const carry = getEditingRow(row.original.id)?.value

                  if (!carry) {
                    toast({
                      variant: 'destructive',
                      description: 'Carry % is required',
                      duration: 2000
                    })

                    return
                  }

                  updateCarry(
                    { carry_id: row.original.id, carry: carry },
                    {
                      onSuccess: async () => {
                        toast({
                          variant: 'success',
                          description: `Carry updated successfully`,
                        })
                        await queryClient.invalidateQueries({
                          queryKey: opportunityKeyFactory.carries(+opportunity_id)
                        })
                        removeRowToEdit(row.original.id)
                      },
                      onError: error => {
                        toast({
                          variant: 'destructive',
                          description: error.message ?? 'Something went wrong',
                          duration: 2000
                        })
                      }
                    }
                  )
                }}
              >
                <Save className='w-4 h-4 text-success'/>
              </Button>
              <Button
                type='button'
                size='sm'
                variant='ghost'
                onClick={() => { removeRowToEdit(row.original.id) }}
              >
                <X className='w-4 h-4'/>
              </Button>
            </>
          ) : (
            <>
              <Button
                type='button'
                size='sm'
                variant='ghost'
                onClick={() => { addOrUpdateRowToEdit({ id: row.original.id, value: row.original.carry }) }}
              >
                <Pencil className='w-4 h-4 text-success'/>
              </Button>
              <Button
                type='button'
                size='sm'
                variant='ghost'
                onClick={() => { setDeletingRowId(row.original.id) }}
              >
                <Trash2 className='w-4 h-4 text-archived'/>
              </Button>
            </>
          )}
        </>
      )
    }
  ]
}
