import { type ComponentPropsWithoutRef } from 'react'
import { EditableTableProvider } from '../../../contexts/EditableTable.context'

export function ContextTableWrapper({ children }: ComponentPropsWithoutRef<'div'>) {
  return (
    <EditableTableProvider>
      {children}
    </EditableTableProvider>
  )
}
