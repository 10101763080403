import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useEditTableContext } from 'contexts/EditableTable.context'
import { useDeleteOpportunityCarryMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from 'components/ui/AlertDialog'
import { Button } from 'components/ui/Button'
import { LoadingButton } from 'components/ui/LoadingButton'

export function DeleteOpportunityDialog() {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { id : opportunity_id } = useParams<{ id: string }>()
  const { mutate: deleteCarry, isLoading } = useDeleteOpportunityCarryMutate()
  const { deletingRowId, setDeletingRowId } = useEditTableContext()

  const confirmDelete = () => {
    if (!deletingRowId) return

    deleteCarry(
      deletingRowId,
      {
        onSuccess: () => {
          setDeletingRowId(null)

          toast({
            variant: 'success',
            description: 'Carry deleted successfully',
          })

          queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.carries(+opportunity_id)
          })
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  const handleCancel = () => {
    setDeletingRowId(null)
  }

  return (
    <AlertDialog open={!!deletingRowId} onOpenChange={handleCancel}>
      <AlertDialogContent className='max-w-3xl'>
        <AlertDialogHeader>
          <AlertDialogTitle>Confirm delete</AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogDescription className='flex flex-col'>
          <span>Are you sure you want to delete this carry?</span>
        </AlertDialogDescription>

        <AlertDialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
          <Button
            type='button'
            size='sm'
            disabled={isLoading}
            className='flex-1 max-w-[262px]'
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            type='button'
            loading={isLoading}
            size='sm'
            className='flex-1 max-w-[262px] bg-archived'
            onClick={confirmDelete}
          >
            Delete
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
