import React, { useState } from 'react'
import { useParams } from 'react-router'
import ScrollContainer from '../../components/core/ScrollContainer'
import Header from '../../components/core/Header'
import { Content } from '../../constants/StyleComponents'
import HeaderNew from 'components/core/HeaderNew'
import OpportunityCarriesV2 from 'components/core/v2/deal/OpportunityCarries'
import { ContextTableWrapper } from 'components/core/v2/ContextTableWrapper.tsx'

/**
 * Component for showing commitments for a deal
 * @returns {JSX.Element}
 * @constructor
 */
export default function DealCarryRoute({ history }) {
  const { id } = useParams()

  const [isScrolled, setIsScrolled] = useState(false)

  const headerAction = {
    label: 'back',
    icon: 'arrow-left',
    action: () => history.push(`/admin/deals/${id}`)
  }

  return (
    <ScrollContainer hasHeader isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
      <HeaderNew />

      <Content isAdmin>
        <ContextTableWrapper value={{}}>
          <OpportunityCarriesV2 id={id} />
        </ContextTableWrapper>
      </Content>
    </ScrollContainer>
  )
}
