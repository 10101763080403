import { useMutation, useQueryClient } from '@tanstack/react-query'
import { userKeyFactory } from 'helpers/api/factories/userKey'
import { EntitiesResponse, UserSearchResponse } from 'helpers/api/get-api'
import {
  addNewEntityName,
  AddNewEntityParams,
  addOrUpdateFund,
  AddOrUpdateFundParams,
  changePassword,
  ChangePasswordParams,
  createClientPassword,
  CreateClientPasswordParams,
  createInvestor,
  CreateInvestorParams,
  createProperty,
  CreatePropertyParams,
  DataRoomParams,
  deleteEntityName,
  deleteFund,
  DeleteFundParams,
  DueDiligenceParams,
  editEntityName,
  EditEntityParams,
  InviteMemberParams,
  inviteMemberToOrganization,
  KeyPointsParams,
  logIn,
  LoginParams,
  mergeMegerIntoOrganization,
  MergeMemberParams,
  OpportunitySummaryParams,
  removeAdminMemberFromOrganization,
  removeMemberFromOrganization,
  RemoveMemberParams,
  saveHubspotContact,
  SaveHubspotParams,
  updateCompanyUpdates,
  updateDataRoom,
  updateDueDiligence,
  updateKeyPoints,
  updateOpportunity,
  updateOpportunitySummary,
  updateOrganizationName,
  UpdateOrganizationParams,
  updateOpportunityCarry,
  UpdateOpportunityCarryParams,
  deleteOpportunityCarry,
  BulkUpdateOpportunityCarriesParams,
  bulkUpdateOpportunityCarries,
  CreateCarryUserParams,
  createCarryUser,
  createOrUpdateUser,
  CreateUserParams
} from 'helpers/api/post-api'
import {
  authenticatedFetch,
  currentPlatform,
  logOut,
  sendForgotPasswordEmail,
  signNDA,
  validateEmail
} from 'services/authentication'
import configuration from 'services/config'
import { DataStore } from 'services/DataStore'
import type {
  AddUpdateFundResponse,
  InvestorResponse,
  Member,
  OpportunityResponse,
  Organization,
  PropertiesResponse,
  UserInfo,
  OpportunityCarriesResponse
} from 'types/api-types'

export function useUserNotificationMutate() {
  const queryClient = useQueryClient()

  return useMutation<unknown, unknown, string>({
    mutationFn: id => {
      return authenticatedFetch(`notifications/${id}`, {
        body: JSON.stringify({
          read: true
        }),
        method: 'put'
      })
    },
    onSuccess: async data => {
      await queryClient.invalidateQueries({
        queryKey: userKeyFactory.notification(),
        refetchType: 'all'
      })
    }
  })
}

export function useValidateEmailMutate() {
  return useMutation<string | null, unknown, string>({
    mutationFn: email => {
      return validateEmail(encodeURIComponent(email))
    }
  })
}

export function useUserRegistrationMutate() {
  return useMutation({
    mutationFn: params => {
      return fetch(configuration.apiUrl + 'register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          platform: currentPlatform()
        },
        body: JSON.stringify(params)
      })
    }
  })
}

export function useSaveHubspotContactMutate() {
  return useMutation<unknown, unknown, SaveHubspotParams>({
    mutationFn: params => {
      return saveHubspotContact(params)
    }
  })
}

export function useLoginMutate() {
  return useMutation<Response, Error, LoginParams>({
    mutationFn: params => {
      return logIn(params)
    }
  })
}

export function useSendResetPasswordEmailMutate() {
  return useMutation<unknown, Error, string>({
    mutationFn: params => {
      return sendForgotPasswordEmail(params)
    }
  })
}

export function useCreateClientPasswordMutate() {
  return useMutation<unknown, Error, CreateClientPasswordParams>({
    mutationFn: params => createClientPassword(params)
  })
}

export function useLogoutMutate() {
  return useMutation({
    mutationFn: () => {
      return logOut(false)
    }
  })
}

export function useSignNdaAgreementMutate() {
  return useMutation({
    mutationFn: () => {
      return signNDA()
    }
  })
}

export function useUpdateOrganizationNameMutate() {
  const queryClient = useQueryClient()

  return useMutation<Organization, Error, UpdateOrganizationParams>({
    mutationFn: params => updateOrganizationName(params),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: userKeyFactory.member(variables.userId),
        refetchType: 'all'
      })
      await queryClient.invalidateQueries({
        queryKey: userKeyFactory.user(),
        type: 'all'
      })
    }
  })
}

export function useInviteMemberToOrganizationMutate() {
  const queryClient = useQueryClient()

  return useMutation<{ members: Member[]; message: string }, Error, InviteMemberParams>({
    mutationFn: params => inviteMemberToOrganization(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: userKeyFactory.user(),
        type: 'all'
      })
    }
  })
}

export function useRemoveMemberFromOrganizationMutate() {
  const queryClient = useQueryClient()

  return useMutation<{ message: string }, Error, RemoveMemberParams>({
    mutationFn: params => removeMemberFromOrganization(params),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: userKeyFactory.member(variables.userId),
        refetchType: 'all'
      })
      await queryClient.invalidateQueries({
        queryKey: userKeyFactory.user(),
        type: 'all'
      })
    }
  })
}

export function useRemoveAdminMemberFromOrganizationMutate() {
  const queryClient = useQueryClient()

  return useMutation<any, Error, number>({
    mutationFn: params => removeAdminMemberFromOrganization(params),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: userKeyFactory.user(),
        type: 'all'
      })
    }
  })
}

export function useEditMemberStatusFromOrganizationMutate() {
  const queryClient = useQueryClient()

  return useMutation<UserInfo, Error, { member: UserInfo; status: string }>({
    mutationFn: async params => {
      // note: The original code used DataStore. I don't know how this is set up.
      return await DataStore.update('users', params.member.id, {
        ...params.member,
        status: params.status
      })
    },
    onSuccess: async data => {
      await queryClient.invalidateQueries({
        queryKey: userKeyFactory.member(data.id),
        refetchType: 'all'
      })
      await queryClient.invalidateQueries({
        queryKey: userKeyFactory.user(),
        type: 'all'
      })
    }
  })
}

export function useMergeMemberInOrganizationMutate() {
  const queryClient = useQueryClient()

  return useMutation<{ members: Member[]; message: string }, Error, MergeMemberParams>({
    mutationFn: mergeMegerIntoOrganization,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: userKeyFactory.user(),
        type: 'all'
      })
    }
  })
}

export function useResendApprovalEmailMutate() {
  return useMutation<unknown, Error, number>({
    mutationFn: id => authenticatedFetch(`users/${id}/resend_approval`, { method: 'GET' })
  })
}

export function useAddNewEntityMutate() {
  return useMutation<{ entity: EntitiesResponse; message: string }, Error, AddNewEntityParams>({
    mutationFn: addNewEntityName
  })
}

export function useEditEntityNameMutate() {
  return useMutation<{ entity: EntitiesResponse; message: string }, Error, EditEntityParams>({
    mutationFn: editEntityName
  })
}

export function useDeleteEntityMutate() {
  return useMutation<{ entity: EntitiesResponse; message: string }, Error, number>({
    mutationFn: deleteEntityName
  })
}

export function useResetPasswordMutate() {
  return useMutation<unknown, Error, ChangePasswordParams>({
    mutationFn: changePassword
  })
}

export function useUpdateOpportunityMutate() {
  return useMutation<OpportunityResponse, Error, Partial<OpportunityResponse>>({
    mutationFn: updateOpportunity
  })
}

export function useCreateInvestorMutate() {
  return useMutation<InvestorResponse, Error, CreateInvestorParams>({
    mutationFn: params => createInvestor(params)
  })
}

export const useUpdateInvestorMutate = useCreateInvestorMutate // alias to not break existing code

export function useCreatePropertyMutate() {
  return useMutation<PropertiesResponse, Error, CreatePropertyParams>({
    mutationFn: createProperty
  })
}

export function useAddOrUpdateFund() {
  return useMutation<AddUpdateFundResponse, Error, AddOrUpdateFundParams>({
    mutationFn: params => addOrUpdateFund(params)
  })
}

export function useDeleteFund() {
  return useMutation<OpportunityResponse, Error, DeleteFundParams>({
    mutationFn: params => deleteFund(params)
  })
}

export function useEditCommitmentRequirementMutate() {
  return useMutation<OpportunityResponse, Error, Partial<OpportunityResponse>>({
    mutationFn: updateOpportunity
  })
}

export function useUpdateKeyPointsMutate() {
  return useMutation<OpportunityResponse, Error, KeyPointsParams>({
    mutationFn: updateKeyPoints
  })
}

export function useEditSummaryMutate() {
  return useMutation<OpportunityResponse, Error, OpportunitySummaryParams>({
    mutationFn: updateOpportunitySummary
  })
}

export function useUpdateDueDiligenceMutate() {
  return useMutation<OpportunityResponse, Error, DueDiligenceParams, OpportunityResponse>({
    mutationFn: updateDueDiligence
  })
}

export function useUpdateDataRoomMutate() {
  return useMutation<OpportunityResponse, Error, DataRoomParams, OpportunityResponse>({
    mutationFn: updateDataRoom
  })
}

export function useUpdateCompanyUpdatesMutate() {
  return useMutation<OpportunityResponse, Error, DataRoomParams, OpportunityResponse>({
    mutationFn: updateCompanyUpdates
  })
}

export function useUpdateVideosMutate() {
  return useMutation<OpportunityResponse, Error, DataRoomParams, OpportunityResponse>({
    mutationFn: updateCompanyUpdates
  })
}

export function useUpdateOpportunityCarryMutate() {
  return useMutation<OpportunityCarriesResponse, Error, UpdateOpportunityCarryParams>({
    mutationFn: updateOpportunityCarry
  })
}

export function useDeleteOpportunityCarryMutate() {
  return useMutation<{message: string}, Error, number>({
    mutationFn: deleteOpportunityCarry
  })
}

export function useBulkUpdateOpportunityCarriesMutate() {
  return useMutation<{message: string}, Error, BulkUpdateOpportunityCarriesParams, {message: string}>({
    mutationFn: params => bulkUpdateOpportunityCarries(params)
  })
}

export function useCreateCarryUserMutate() {
  return useMutation<UserSearchResponse, Error, CreateCarryUserParams>({
    mutationFn: createCarryUser
  })
}

export function useCreateUserMutate() {
  return useMutation<UserInfo, Error, CreateUserParams>({
    mutationFn: params => createOrUpdateUser(params)
  })
}
