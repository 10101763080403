import React, { useEffect, useState } from 'react'
import { Button } from 'components/ui/Button'
import { Table } from 'components/core/v2/Table'
import { useTable } from 'hooks/useTable.hooks'
import { dollarFormat } from 'constants/DollarsFormat'
import { AddCarryClientDialog } from 'components/core/v2/deal/dialogs/AddCarryClientDialog'
import { Section } from 'components/core/v2/deal/layouts/Section'
import { Article } from 'components/core/v2/deal/layouts/Article'
import { useToast } from 'hooks/userToast.hooks'
import { useGetOpportunity, useGetOpportunityCarries, useGetOpportunityCarryTrackingByMoic } from 'hooks/api/useQuery.hooks'
import { ValuationDetails } from './carries/ValuationDetails'
import { OpportunityCarriesResponse, OpportunityResponse } from 'types/api-types'
import { Columns } from 'components/core/v2/deals/carries/Columns'
import { DeleteOpportunityDialog } from 'components/core/v2/deal/carries/DeleteOpportunityDialog'
import { useEditTableContext } from 'contexts/EditableTable.context'
import { useBulkUpdateOpportunityCarriesMutate } from 'hooks/api/useMutation.hooks'

interface OpportunityCarriesProps {
  id: string
}

export interface CarryData {
  id: number
  user: { name: string }
  carry: number
  carryTracking: number
}

const calculateCarry = (carriesData: CarryData[] = [], carryTracking: OpportunityResponse['carry_tracking']) => {
  if (!carryTracking) return carriesData

  return carriesData.map(carry => {
    const parsedCarry = parseFloat(carry.carry.toString()) || 0

    return {
      ...carry,
      carry: parsedCarry,
      carryTracking: (Number(carryTracking?.overall?.carry) || 0) * (parsedCarry) * 0.01 || 0
    }
  })
}

const OpportunityCarries: React.FC<OpportunityCarriesProps> = ({ id }) => {
  const [openClientModal, setOpenClientModal] = useState(false)
  const [usingProjectedMoic, setUsingProjectedMoic] = useState(false)

  const { toast } = useToast()
  const { editingRows, clearEditingRows } = useEditTableContext()
  const { mutate: bulkUpdate } = useBulkUpdateOpportunityCarriesMutate()
  const { data: deal } = useGetOpportunity(id)
  const { data: carries, isLoading: isLoadingCarries } = useGetOpportunityCarries(+id)
  const { data: potentialMoicCarryTracking, isLoading: isLoadingPotentialmoic, refetch } = useGetOpportunityCarryTrackingByMoic(+id, Number(deal?.potential_moic))

  const carryData = calculateCarry(
    carries as unknown as CarryData[],
    (usingProjectedMoic ? potentialMoicCarryTracking : deal?.carry_tracking) || {} as OpportunityResponse['carry_tracking']
  )

  const table = useTable({
    columns: Columns(),
    data: carryData,
    initialState: {
      sorting: [
        {
          id: 'carry',
          desc: true,
        },
      ],
    },
    defaultColumn: {
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    }
  })

  const handleSaveAll = () => {
    bulkUpdate({
      opportunity_id: +id,
      carries: editingRows.map(row => ({
        id: row.id,
        carry: row.value.toString()
      } as OpportunityCarriesResponse))
    }, {
      onSuccess: async () => {
        toast({
          variant: 'success',
          description: 'All carries updated successfully',
        })

        clearEditingRows()
      },
      onError: error => {
        toast({
          variant: 'destructive',
          description: error.message ?? 'Something went wrong',
          duration: 2000
        })
      }
    })
  }

  useEffect(() => {
    // Refetch carry data when potential moic changes
    // invalidating query cache does not work with updated potential moic
    // for some reason (race condition?)
    // so manual refetch is needed
    refetch()
  }, [deal?.potential_moic])

  return (
    <>
      <div className="flex justify-between items-center">
        <h2>Carry for {deal?.name}</h2>
        <Button
          type='button'
          variant='success'
          size='sm'
          onClick={() => setOpenClientModal(true)}
        >
          Add client
        </Button>
      </div>
      <Section>
        <Article>
          {deal && <ValuationDetails id={deal.id} />}
        </Article>
      </Section>
      <Section>
        <Article>
          <div className="inline-flex justify-end w-full mt-4">
            <label className="inline-flex items-center cursor-pointer">
              <span className="mr-2 text-sm font-medium">MOIC</span>
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={usingProjectedMoic}
                disabled={isLoadingPotentialmoic}
                onChange={(e) => { setUsingProjectedMoic(e.target.checked) }}
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-[#51b749] dark:peer-focus:ring-[#51b749] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#51b749]"></div>
              <span className="ml-2 text-sm font-medium">Projected MOIC</span>
            </label>
          </div>
        </Article>
      </Section>
      <Section>
        <Article>
          <div className="mt-4">
            {isLoadingCarries ? (
              <span>Loading...</span>
            ) : (
              carryData.length > 0 ? (
                <Table table={table} />
              ) : (
                <span>No Carry Data Available</span>
              )
            )}
          </div>
        </Article>
      </Section>
      <Section>
        <Article>
          <div className="inline-flex justify-end w-full mt-4 mb-8">
            {editingRows.length > 0 && (
              <>
                <Button
                  type='button'
                  variant='default'
                  onClick={clearEditingRows}
                  className='mr-2'
                >
                  Cancel All
                </Button>
                <Button
                  type='button'
                  variant='success'
                  onClick={handleSaveAll}
                >
                  Save All
                </Button>
              </>
            )}
          </div>
        </Article>
      </Section>

      <AddCarryClientDialog
        id={+id}
        open={openClientModal}
        toggleOpen={setOpenClientModal}
      />

      <DeleteOpportunityDialog />
    </>
  )
}

export default OpportunityCarries
